<template>
   <div class="row">
      <div class="col-12">
         <div class="card mb-2">
            <div class="card-body pb-0">
               <div class="row m-0">
                  <div class="col-12 col-sm-6 col-md-4 px-1 mb-3">
                     <label><i class="far fa-store color-theme font-12 me-1"></i> Loja</label>
                     <v-select :options="listaLojas" label="nome2" v-model="pesquisa.loja" placeholder="Selecione uma loja" />
                  </div>
                  <div class="col-6 col-md-3 px-1 mb-3">
                     <label><i class="far fa-calendar-check color-theme font-12 me-1"></i> Data Inicial</label>
                     <datePicker :format="'dd/MM/yyyy'" :language="ptBR" v-model="pesquisa.dataInicial"></datePicker>
                  </div>
                  <div class="col-6 col-md-3 px-1 mb-3">
                     <label><i class="far fa-calendar-times color-theme font-12 me-1"></i> Data final</label>
                     <datePicker :format="'dd/MM/yyyy'" :language="ptBR" v-model="pesquisa.dataFinal"></datePicker>
                  </div>
                  <div class="col-12 col-sm-6 col-md-2 px-1 mb-3 align-self-end text-center">
                     <button type="button" class="btn btn-primary w-100" @click="buscarVendas">
                        <i class="fas fa-search font-13"></i>
                     </button>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="col-12" v-if="resultEcommerce != null">
         <ecommerce :ecommerce="resultEcommerce" @verComprovantes="buscarComprovantes($event, true)" @imprimir="imprimirEcommerce" />
      </div>

      <!-- Relatorio ecommerce -->
      <div id="relatorio" class="row d-none">
         <div class="col-12 p-0 w-100" v-if="resultEcommerce != null">
            <div class="float-end text-center">
               <h6>De {{ resultEcommerce.dataInicio.replace(/-/g, "/") }} até {{ resultEcommerce.dataFim.replace(/-/g, "/") }}</h6>
            </div>
            <div class="text-dark">
               <h5 class="mb-3">Ecommerce</h5>
            </div>
      
            <table class="w-100">
               <thead>
                  <tr style="font-size: 10px;">
                     <td class="text-center py-2 border border-dark" style="width: 20%; font-weight: 600;">Loja</td>
                     <td class="text-center py-2 border border-dark" style="width: 20%; font-weight: 600;">Dinheiro</td>
                     <td class="text-center py-2 border border-dark" style="width: 20%; font-weight: 600;">Débito</td>
                     <td class="text-center py-2 border border-dark" style="width: 20%; font-weight: 600;">Crédito</td>
                     <td class="text-center py-2 border border-dark" style="width: 20%; font-weight: 600;">Total</td>
                  </tr>
               </thead>
               <tbody>
                  <tr style="font-size: 10px;" v-for="(loja, index) in resultEcommerce.lojas" :key="index" :style="index % 2 == 0 ? 'background: #3333' : ''">
                     <td class="text-center p-1 border border-dark" style="width: 20%; font-weight: 600;">{{ loja.numeroLoja }}</td>
                     <td class="text-center p-1 border border-dark" style="width: 20%;">
                        <small class="font-8 color-theme">R$</small> {{ parseFloat(loja.valorDinheiro).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                     </td>
                     <td class="text-center p-1 border border-dark" style="width: 20%;">
                        <small class="font-8 color-theme">R$</small> {{ parseFloat(loja.valorDebito).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                     </td>
                     <td class="text-center p-1 border border-dark" style="width: 20%;">
                        <small class="font-8 color-theme">R$</small> {{ parseFloat(loja.valorCredito).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                     </td>
                     <td class="text-center p-1 border border-dark" style="width: 20%; font-weight: 600;">
                        <small class="font-8 color-theme">R$</small> {{ parseFloat(loja.valorTotal).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                     </td>
                  </tr>
               </tbody>
               <tbody>
                  <tr style="font-size: 10px;">
                     <td class="text-center p-1 border border-dark" style="width: 20%; font-weight: 800;">Total</td>
                     <td class="text-center p-1 border border-dark" style="width: 20%; font-weight: 600;">
                        <small class="font-8 color-theme">R$</small> {{ parseFloat(resultEcommerce.totalDinheiro).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                     </td>
                     <td class="text-center p-1 border border-dark" style="width: 20%; font-weight: 600;">
                        <small class="font-8 color-theme">R$</small> {{ parseFloat(resultEcommerce.totalDebito).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                     </td>
                     <td class="text-center p-1 border border-dark" style="width: 20%; font-weight: 600;">
                        <small class="font-8 color-theme">R$</small> {{ parseFloat(resultEcommerce.totalCredito).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                     </td>
                     <td class="text-center p-1 border border-dark" style="width: 20%; font-weight: 800;">
                        <small class="font-8 color-theme">R$</small> {{ parseFloat(resultEcommerce.vendaTotal).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                     </td>
                  </tr>
               </tbody>
            </table>
         </div>
      </div>

      <!-- modalComprovantes -->
      <div class="modal fade" id="modalComprovantes" tabindex="-1" aria-labelledby="modalComprovantesLabel" aria-hidden="true">
         <div class="modal-dialog modal-xxl modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalComprovantesLabel">
                     Comprovantes {{ modal.pesquisa != null && modal.pesquisa.numeroLoja != 0 ? ' - Loja '+ modal.pesquisa.numeroLoja : '' }}
                  </h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body" v-if="modal.comprovantes.length > 0">
                  <div class="cards">
                     <comprovante v-for="(cupom, index) in modal.comprovantes" :key="index" :index="index" :cupom="cupom" />
                  </div>

                  <div class="col-12 px-1 mt-3 mb-2 text-center" v-if="modal.comprovantes.length % 30 == 0">
                     <button type="button" class="btn btn-secondary btn-md font-12" @click="buscarComprovantes(modal.pesquisa, false)">Ver mais</button>
                  </div>
               </div>
               <div class="modal-body my-5 text-center font-16" v-else>Nenhuma informação encontrada</div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'
import { ptBR } from 'vuejs-datepicker/dist/locale'
import ecommerce from '@/components/admin/vendas/Ecommerce.vue'
import comprovante from '@/components/admin/vendas/Comprovante.vue'

export default {
	name: 'Vendas',
   data: function () {
      return {
         pesquisa: {'loja': '', 'dataInicial': '', 'dataFinal': ''},
         listaLojas: [],
         ptBR: ptBR,
         resultEcommerce: null,
         relatorio: null,
         modal: {'comprovantes': [], 'comprovantesPagina': 0, 'pesquisa': null}
      }
   },
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
         logado: state => state.logado,
         urlRest: state => state.urlRest
      })
   },
   components: {
      ecommerce, comprovante
   },
   methods: {
      async imprimirEcommerce() {
         await this.$htmlToPaper('relatorio', {
            styles: [
               'https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css'
            ]
         });
      },
      buscarVendas : function () {
         if (this.pesquisa.loja != null) {
            let ref = this;
            this.$store.dispatch('alternarTelaCarregamento', true)

            this.$axios({
               method: 'get',
               url: this.urlRest +'adminsales/getSales',
               headers: {
                  'Authorization': 'Bearer ' + this.logado.token +'@@@'+ this.dadosUsuario.admin.usuarioUsername
               },
               params: {
                  idUsuario: this.dadosUsuario.admin.usuarioId,
                  loja: this.pesquisa.loja.id,
                  dataInicial: this.pesquisa.dataInicial.getDate()+'-'+(this.pesquisa.dataInicial.getMonth() + 1)+'-'+this.pesquisa.dataInicial.getFullYear(),
                  dataFinal: this.pesquisa.dataFinal.getDate()+'-'+(this.pesquisa.dataFinal.getMonth() + 1)+'-'+this.pesquisa.dataFinal.getFullYear()
               }
            }).then(response => {
               this.resultEcommerce = response.data

            }).catch(function (error) {
               if (error.response != undefined) {
                  if (error.response.status == 408) {
                     ref.$store.dispatch('deslogarAdmin')

                     ref.$toast.fire({
                        icon: 'warning',
                        title: 'Sessão expirada!'
                     });
                  } else {
                     ref.$toast.fire({
                        icon: 'error',
                        title: 'Erro: ' + error.response.status
                     });
                  }
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: error
                  });
               }
            }).finally(() => {
               this.$store.dispatch('alternarTelaCarregamento', false)
            });
         } else {
            this.$toast.fire({
               icon: 'error',
               title: 'Selecione uma loja!'
            });
         }
      },
      buscarComprovantes : function (obj, reset) {
         let ref = this;

         if (reset) {
            this.modal.comprovantes = []
            this.modal.pesquisa = null
            this.modal.comprovantesPagina = 0
         }

         $('#modalComprovantes').modal('show')

         this.$store.dispatch('alternarTelaCarregamento', true)
         this.modal.pesquisa = obj;

         this.$axios({
            method: 'get',
            url: this.urlRest +'adminsales/getVouchers',
            headers: {
               'Authorization': 'Bearer ' + this.logado.token +'@@@'+ this.dadosUsuario.admin.usuarioUsername
            },
            params: {
               loja: this.modal.pesquisa.idLoja,
               dataInicial: this.modal.pesquisa.dataInicio,
               dataFinal: this.modal.pesquisa.dataFim,
               formaPagamento: this.modal.pesquisa.formaPagamento,
               pagina: this.modal.comprovantesPagina
            }
         }).then(response => {
            if (ref.modal.comprovantesPagina == 0) {
               ref.modal.comprovantes = []
            }

            ref.modal.comprovantesPagina++;

            response.data.forEach(p => {
               if (!ref.modal.comprovantes.includes(p)) {
                  ref.modal.comprovantes.push(p)
               }
            });
         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  ref.$store.dispatch('deslogarAdmin')

                  ref.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               ref.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      }
   },
   mounted() {
      let todasLojas = {
         "id": 0,
         "nome2": 'Todas as lojas'
      }

      this.listaLojas = []
      Array.prototype.push.apply(this.listaLojas, this.dadosUsuario.admin.usuarioLojas)
      this.listaLojas.unshift(todasLojas)

      this.pesquisa.loja = todasLojas
      this.pesquisa.dataInicial = new Date()
      this.pesquisa.dataFinal = new Date()
   }
}

</script>

<style scoped>

.row {
   margin: 0;
}

.row>* {
   padding: 0;
}

.cards {
   display: grid;
   grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
   gap: 0.5rem;
   padding: 0;
   list-style-type: none;
}

</style>