<template>
   <div class="card">
      <div class="card-body p-3">
         <div class="row">
            <div class="col-12 font-17 mb-3">
               <div class="mt-1 float-start"><i class="far fa-desktop color-theme font-16 me-2"></i>Ecommerce</div>
               <a role="button" class="badge bg-theme font-12 float-end ms-2 d-none d-sm-inline" @click="imprimir">
                  <i class="fal fa-print me-1"></i> Imprimir
               </a>
               <div class="d-none d-lg-inline badge bg-light float-end">
                  <i class="far fa-info-circle me-1"></i>
                  Vendas contabilizadas no ecommerce de {{ ecommerce.dataInicio.replace(/-/g, "/") }} até {{ ecommerce.dataFim.replace(/-/g, "/") }}
               </div>
            </div>

            <div class="col-12">
               <div class="row text-center mb-1 weight-700">
                  <div class="col">
                     <i class="far fa-store me-2 font-13 color-theme"></i>Loja
                  </div>
                  <div class="col d-none d-sm-inline" title="Valor em dinheiro">
                     <i class="far fa-dollar-sign me-2 font-13 color-theme"></i>Dinheiro
                  </div>
                  <div class="col d-none d-sm-inline" title="Vendas em débito">
                     <i class="far fa-credit-card-front me-2 font-13 color-theme"></i>Débito
                  </div>
                  <div class="col d-none d-sm-inline" title="Vendas em crédito">
                     <i class="fas fa-credit-card me-2 font-13 color-theme"></i>Crédito
                  </div>
                  <div class="col">
                     <i class="fas fa-equals me-2 font-13 color-theme"></i>Total
                  </div>
               </div>
            </div>

            <ecommerceLoja v-for="(loja, index) in ecommerce.lojas" :key="index" :loja="loja" :index="index" @verComprovantes="getComprovantesLoja($event)" />

            <div class="col-12 mt-2">
               <div class="row text-center color-theme weight-700">
                  <div class="col">Total</div>
                  <div class="col d-none d-sm-inline">
                     <span class="cursor-pointer" @click="getComprovantes('DINHEIRO (ECOMMERCE)')">
                        <small class="font-10">R$</small> {{ parseFloat(ecommerce.totalDinheiro).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                     </span>
                  </div>
                  <div class="col d-none d-sm-inline">
                     <span class="cursor-pointer" @click="getComprovantes('CARTAO_DE_DEBITO (ECOMMERCE)')">
                        <small class="font-10">R$</small> {{ parseFloat(ecommerce.totalDebito).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                     </span>
                  </div>
                  <div class="col d-none d-sm-inline">
                     <span class="cursor-pointer" @click="getComprovantes('CARTAO_DE_CREDITO (ECOMMERCE)')">
                        <small class="font-10">R$</small> {{ parseFloat(ecommerce.totalCredito).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                     </span>
                  </div>
                  <div class="col">
                     <small class="font-10">R$</small> {{ parseFloat(ecommerce.vendaTotal).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import ecommerceLoja from '@/components/admin/vendas/EcommerceLoja.vue'

export default {
	name: 'Ecommerce',
   props: ['ecommerce'],
   components: {
      ecommerceLoja
   },
   methods: {
      imprimir() {
         this.$emit('imprimir')
      },
      getComprovantes : function (formaPagamento) {
         this.$emit('verComprovantes', {
            'formaPagamento': formaPagamento,
            'idLoja': 0,
            'numeroLoja': 0,
            'dataInicio': this.ecommerce.dataInicio,
            'dataFim': this.ecommerce.dataFim
         })
      },
      getComprovantesLoja : function (obj) {
         this.$emit('verComprovantes', {
            'formaPagamento': obj.formaPagamento,
            'idLoja': obj.idLoja,
            'numeroLoja': obj.numeroLoja,
            'dataInicio': this.ecommerce.dataInicio,
            'dataFim': this.ecommerce.dataFim
         })
      }
   }
}

</script>

<style scoped>

.row {
   margin: 0;
}

.row>* {
   padding: 0;
}

</style>