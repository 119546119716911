<template>
   <div class="col-12">
      <div class="card hover mb-1" :class="index % 2 == 1 ? 'invert' : ''">
         <div class="card-body py-2 px-0">
            <div class="row text-center my-1">
               <div class="col">{{ loja.numeroLoja }}</div>
               <div class="col d-none d-sm-inline" title="Valor de dinheiro">
                  <span class="cursor-pointer" @click="buscarComprovantes('DINHEIRO (ECOMMERCE)')">
                     <small class="font-10">R$</small> {{ parseFloat(loja.valorDinheiro).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                  </span>
               </div>
               <div class="col d-none d-sm-inline" title="Vendas em débito">
                  <span class="cursor-pointer" @click="buscarComprovantes('CARTAO_DE_DEBITO (ECOMMERCE)')">
                     <small class="font-10">R$</small> {{ parseFloat(loja.valorDebito).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                  </span>
               </div>
               <div class="col d-none d-sm-inline" title="Vendas em crédito">
                  <span class="cursor-pointer" @click="buscarComprovantes('CARTAO_DE_CREDITO (ECOMMERCE)')">
                     <small class="font-10">R$</small> {{ parseFloat(loja.valorCredito).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                  </span>
               </div>
               <div class="col">
                  <span class="cursor-pointer" @click="buscarComprovantes('TOTAL (ECOMMERCE)')">
                     <small class="font-10">R$</small> {{ parseFloat(loja.valorTotal).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                  </span>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

export default {
	name: 'EcommerceLoja',
   props: ['loja', 'index'],
   methods: {
      buscarComprovantes : function (formaPagamento) {
         this.$emit('verComprovantes', {
            'formaPagamento': formaPagamento,
            'idLoja': this.loja.idLoja,
            'numeroLoja': this.loja.numeroLoja
         })
      }
   }
}

</script>

<style scoped>

.row {
   margin: 0;
}

.row>* {
   padding: 0;
}

.cards {
   display: grid;
   grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
   gap: 0.5rem;
   padding: 0;
   list-style-type: none;
}

</style>