<template>
   <div class="card hover mb-0">
      <div class="text-center">
         <img src="@/assets/admin/images/nfe.png" alt="Icone" class="cardImagem">
         <div class="font-20 weight-600">
            <small class="font-10 color-theme">R$</small> {{ parseFloat(cupom.valor).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
         </div>
         <div class="mb-2 font-10 text-secondary">(Pdv {{ cupom.idPdv }})</div>
         <div class="limitador my-1 mx-auto font-11" v-if="cupom.formaPagto != undefined">
            <i class="far fa-stream color-theme me-1"></i> {{ cupom.formaPagto.replace(/_/g, " ") }} {{ cupom.idUsuario ? '(ID '+ cupom.idUsuario +')' : '' }}
         </div>
         <div class="limitador my-1 mx-auto font-11" v-else>
            <i class="far fa-store color-theme me-1"></i> Loja {{ cupom.numeroLoja }}
         </div>
         <div class="limitador my-1 mx-auto font-11">
            <i class="far fa-calendar-alt color-theme me-1"></i> {{ cupom.data }}
         </div>
         <span class="badge bg-theme mt-2 mb-3 cursor-pointer" v-if="cupom.idNota != null" @click="imprimirComprovanteVenda(cupom.idNota, cupom.id)"> 
            <i class="fal fa-print me-1"></i> Imprimir
         </span>
         <span class="badge bg-theme mt-2 mb-3 cursor-pointer" v-else> 
            <i class="fas fa-times me-1"></i> Erro
         </span>
      </div>

      <div :id="'printMe'+ cupom.id" class="row d-none">
         <div class="col-12 m-3 px-0" style="width: 350px; font-size: 13px;" v-if="comprovanteSelecionado != null">
            <div class="text-center" style="font-weight: bold;">{{ comprovanteSelecionado.lojaNome }}</div>
            <div class="text-center">CNPJ: {{ comprovanteSelecionado.lojaCnpj }} - IE: {{ comprovanteSelecionado.lojaIe }}</div>
            <div class="text-center">{{ comprovanteSelecionado.lojaEndereco }} <br/> Telefone: {{ comprovanteSelecionado.lojaTelefone }}</div>
            
            <br/>

            <div class="row m-0">
               <div class="me-1 px-0 overflow-hidden" style="width: 98px; white-space: nowrap; text-overflow: clip;">Código</div>
               <div class="me-1 px-0 overflow-hidden" style="width: 115px; white-space: nowrap; text-overflow: clip;">Descrição</div>
               <div class="me-1 px-0 overflow-hidden text-center" style="width: 34px; white-space: nowrap; text-overflow: clip;">Qtd</div>
               <div class="me-1 px-0 overflow-hidden text-end" style="width: 38px; white-space: nowrap; text-overflow: clip;">Un</div>
               <div class="me-1 px-0 overflow-hidden text-end" style="width: 45px; white-space: nowrap; text-overflow: clip;">Total</div>
            </div>
            
            <hr class="my-1" style="border-top: 1.30px dashed #000;">
            
            <div class="row m-0" style="font-size: 11px;" v-for="p in comprovanteSelecionado.produtos" :key="p.id">
               <div class="me-1 px-0 overflow-hidden" style="width: 98px; white-space: nowrap; text-overflow: clip;">{{ p.ean }}</div>
               <div class="me-1 px-0 overflow-hidden" style="width: 115px; white-space: nowrap; text-overflow: clip;">{{ p.nome }}</div>
               <div class="me-1 px-0 overflow-hidden text-center" style="width: 34px; white-space: nowrap; text-overflow: clip;">{{ p.qtd }}</div>
               <div class="me-1 px-0 overflow-hidden text-end" style="width: 38px; white-space: nowrap; text-overflow: clip;">{{ parseFloat(p.valorUn).toFixed(2) }}</div>
               <div class="me-1 px-0 overflow-hidden text-end" style="width: 45px; white-space: nowrap; text-overflow: clip;">{{ parseFloat(p.valorTotal).toFixed(2) }}</div>
            </div>
            
            <br/>
            
            <hr class="my-1" style="border-top: 1.30px dashed #000;">

            <div class="row m-0 mb-1">
               <div class="w-75 px-0">Quantidade Total de Itens</div>
               <div class="w-25 px-0 text-end">{{ parseFloat(comprovanteSelecionado.vendaQtdItens).toLocaleString('pt-br', {minimumFractionDigits: 0, maximumFractionDigits:3}) }}</div>
               <div class="w-75 px-0">Valor Total</div>
               <div class="w-25 px-0 text-end">R$ {{ parseFloat(comprovanteSelecionado.vendaTotal).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}</div>
            </div>
            
            <hr class="my-1" style="border-top: 1.30px dashed #000;">
            
            <div class="row m-0 mb-1">
               <div class="w-50 px-0">Forma de Pagamento</div>
               <div class="w-50 px-0 text-end">Valor Pago</div>

               <div class="w-100 px-0">
                  <div class="row m-0 mb-1" v-for="p in comprovanteSelecionado.pagamentos" :key="p.id">
                     <div class="w-75 px-0">{{ p.formaPgto }}</div>
                     <div class="w-25 px-0 text-end">R$ {{ parseFloat(p.valor).toFixed(2) }}</div>
                  </div>
               </div>
            </div>
            
            <hr class="my-1" style="border-top: 1.30px dashed #000;">
            
            <div class="row m-0 mb-2">
               <div class="col-12 px-0 text-center mb-2">
                  <div style="font-weight: bold;">Consulte pela chave de acesso</div>
                  <div>http://www.fazenda.pr.gov.br/nfce/consulta</div>
                  <div>{{ comprovanteSelecionado.vendaChave }}</div>
               </div>
               <div class="row m-0 px-0" v-if="comprovanteSelecionado.vendaQrCode != null">
                  <qrcode-vue :value="comprovanteSelecionado.vendaQrCode" size="130" level="H" background="#0000" renderAs="svg" class="mb-1 mt-1 px-0" style="height: 130px; width: 130px" />
                  <div class="align-self-center text-center px-0" style="width: 220px;">
                     <div style="font-weight: bold;">Via do Consumidor</div>
                     <div>Nº {{ comprovanteSelecionado.vendaNumeroNota }} - Série {{ comprovanteSelecionado.vendaSerie }}</div>
                     <div>Protocolo de autorização</div>
                     <div>{{ comprovanteSelecionado.vendaProtocolo }}</div>
                     <div>{{ comprovanteSelecionado.vendaData }}</div>
                     <div>Consumidor: {{ comprovanteSelecionado.vendaCpfCliente }}</div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import { mapState, mapMutations } from 'vuex'

export default {
	name: 'Comprovante',
   props: ['cupom', 'index'],
   data: function () {
      return {
         comprovanteSelecionado: null
      }
   },
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
         logado: state => state.logado,
         urlRest: state => state.urlRest
      })
   },
   methods: {
      ... mapMutations([
         'deslogar'
      ]),
      imprimirComprovanteVenda : function (idNota, idCupom) {
         let ref = this;

         this.$store.dispatch('alternarTelaCarregamento', true);
         this.comprovanteSelecionado = null;

         this.$axios({
            method: 'get',
            url: this.urlRest +'adminsales/getVoucher',
            headers: {
               'Authorization': 'Bearer ' + this.logado.token +'@@@'+ this.dadosUsuario.admin.usuarioUsername
            },
            params: {
               nota: idNota
            }
         }).then(response => {
            this.comprovanteSelecionado = response.data;

            setTimeout(function(){
               ref.print(idCupom);
            }, 500);
         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  ref.deslogar();

                  ref.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               ref.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false);
         });
      },
      async print(idCupom) {
         await this.$htmlToPaper('printMe'+idCupom, {
            styles: [
               'https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css'
            ]
         });
      }
   }
}

</script>

<style scoped>

.cardImagem {
	width: 100px;
	height: 100px;
	margin: 8px;
	object-fit: cover;
	cursor: pointer;
}

</style>